<script lang="ts">
export default {
  name: 'app-brand-card-regular'
};
</script>

<script lang="ts" setup>
const props = defineProps({
  img: {
    type: String
  },
  titulo: {
    type: String
  },
  resumen: {
    type: String
  }
});

const slots = useSlots();

const getImage = computed(() => useAssets(`/assets/images/${props.img}`));
// const hasSlot = (name: Readonly<string>): Boolean => !!slots[name];

</script>

<template lang="pug">
article.app-brand-card-regular
  .app-brand-card-regular__header
    p.h2.text--brand-primary {{titulo}}
    p.truncated {{resumen}}
  .app-brand-card-regular__content
    article.app-brand-card-regular__body
      img.img-fluid(:src="img", alt="")
    //- article.app-brand-card-regular__footer(v-if="hasSlot('footer')")
</template>

<style lang="scss">
@import 'index';
</style>